import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import WinterGoods from '@components/WinterGoods/WinterGoods';

const WinterEnPage = ({ location }) => {
  return (
    <Layout location={location} map en>
      <Seo
        title="Rental, Skis, Snowshoes, Crampons & Bob in Champoluc - Tiziano Bieller Sport"
        lang="en"
      />
      <WinterGoods detail en />
    </Layout>
  );
};

export default WinterEnPage;
